import { EnvironmentConfig, EnvironmentType } from './environment-config';

const currentDomain = window.location.hostname.includes('dev.ineedservice.co')
  ? 'dev.ineedservice.co'
  : 'propup-dev.com';

export const environment: EnvironmentConfig = {
  type: EnvironmentType.Dev,
  ver: '1.5.1',
  authBaseUrl: 'https://propupdevb2c.b2clogin.com/propupdevb2c.onmicrosoft.com/oauth2/v2.0',
  apiUrl: 'https://api.propup-dev.com/api',
  openApiUrl: 'https://api.propup-dev.com/openApi',
  notificationsUrl: 'https://api.propup-dev.com/notifications',
  notificationsAllUrl: 'https://api.propup-dev.com/all_notifications',
  disableForm: true,
  showAuthorizeLogs: true,
  showBuildTime: true,
  showEasterEggs: true,
  azureAuthConfig: {
    b2cDirectory: 'propupdevb2c',
    stsBase: 'propupdevb2c.b2clogin.com',
    tenant: 'propupdevb2c.onmicrosoft.com',
    policyKey: 'B2C_1_SignUpSignIn',
    stsServer: 'https://propupdevb2c.b2clogin.com/propupdevb2c.onmicrosoft.com/B2C_1_SignUpSignIn/v2.0',
    stsServerSignInEmail:
      'https://propupdevb2c.b2clogin.com/propupdevb2c.onmicrosoft.com/B2C_1A_signin_with_email/v2.0',
    policyKeySignInEmail: 'B2C_1A_signin_with_email',
    clientID: 'b6a9b7da-a6f9-4664-9772-e93ada0e7c57',
    b2cScopes: 'openid offline_access b6a9b7da-a6f9-4664-9772-e93ada0e7c57',
    redirectUrl: `https://${currentDomain}/accept-token`,
    postLogoutRedirectUrl: `https://${currentDomain}/login`,
    forgotPasswordUrl: '',
  },
};
