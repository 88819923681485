export enum EIcon {
  ANGLE_DOWN = 'angleDown',
  ANGLE_UP = 'angleUp',
  ANGLE_LEFT = 'angleLeft',
  ANGLE_RIGHT = 'angleRight',
  WARNING = 'warning',
  WARNING2 = 'warning2',
  HOME_HEART = 'homeHeart',
  ARROW_DOWN = 'arrowDown',
  ARROW_UP = 'arrowUp',
  ARROW_LEFT = 'arrowLeft',
  WAITING = 'waiting',
  FILE_PLUS = 'filePlus',
  HOME = 'home',
  SAD = 'sad',
  EXPIRATIONS = 'expirations',
  SIGN_OUT = 'signOut',
  FORWARD = 'forward',
  GREEN_FLAG = 'greenFlag',
  RED_FLAG = 'redFlag',
  YELLOW_FLAG = 'yellowFlag',
  DEFAULT_FLAG = 'defaultFlag',
  REPEAT = 'repeat',
  REPEAT_LAPS = 'repeat-laps',
  REPEAT_ONE = 'iconRepeatOne',
  REPEAT2 = 'iconRepeat',
  DOLLAR = 'dollar',
  DONE = 'done',
  DONE_WB = 'doneWB',
  ERROR = 'error',
  PERMISSION_ERROR = 'permissionError',
  QUESTION = 'question',
  SEARCH = 'search',
  TIME_SCHEDULE = 'timeSchedule',
  THUNDER_CIRCLE = 'thunderCircle',
  SHIELD_USER = 'shieldUser',
  PLUS = 'plus',
  USER = 'user',
  BOARD = 'board',
  DASHBOARD = 'dashboard',
  DASHBOARD2 = 'dashboard2',
  DASHBOARD3 = 'dashboard3',
  DASHBOARD4 = 'dashboard4',
  DASHBOARD5 = 'dashboard5',
  USER_GROUP = 'userGroup',
  CHAIR = 'chair',
  LIBRARY = 'library',
  VISIBLE = 'visible',
  FILE_DONE = 'fileDone',
  TIMER = 'timer',
  TOOLS = 'tools-wrench',
  TOOLS_HAMMER_SCREWDRIVER = 'tools-hammer-screwdriver',
  Service = 'tools-hammer',
  ARCHIVE = 'archive',
  BINOCULAR = 'binocular',
  ARROW_FROM_LEFT = 'arrowFromLeft',
  ARROW_FROM_RIGHT = 'arrowFromRight',
  EDIT = 'edit',
  CLOSE = 'close',
  RADIO_SELECTED = 'radioSelected',
  RADIO_DEFAULT = 'radioDefault',
  BUILDING = 'building',
  IPHONE = 'iphone',
  MAIL = 'mail',
  MAIL2 = 'mail2',
  IMPORT = 'import',
  EXPORT = 'export',
  NOTIFICATION = 'notification',
  CHECK = 'check',
  HYPERLINK = 'hyperlink',
  ARROW_TO_BOTTOM = 'arrowToBottom',
  COMMENT = 'comment',
  COMMENT2 = 'comment2',
  COMMENT3 = 'comment3',
  COMMENT4 = 'comment4',
  COMMENT_CIRCLE = 'comment-circle',
  COMMENT_QUESTION = 'comment-question',
  CHECKBOX_SELECTED = 'checkboxSelected',
  CHECKBOX_DEFAULT = 'checkboxDefault',
  CROSS = 'cross',
  SAVE = 'save',
  TICK = 'tick',
  TICKET = 'ticket',
  TICKET2 = 'ticket2',
  MOVE_OUT = 'moveOut',
  HIDDEN = 'hidden',
  EXPAND_ARROWS = 'expandArrows',
  CHART_LINE = 'chartLine',
  NOTIFICATIONS = 'notifications',
  PENCIL = 'pencil',
  SETTINGS = 'settings',
  SETTINGS2 = 'settings2',
  TRASH = 'trash',
  EXPAND = 'expand',
  COLLAPSE = 'collapse',
  BLOCKS = 'blocks',
  MENU = 'menu',
  BULB = 'bulb',
  SUPPORT = 'support',
  BUG = 'bug',
  DOLLAR_CIRCLE = 'dollar_circle',
  LOGO = 'logo',
  EXCHANGE = 'exchange',
  SENDING = 'sending',
  SLEEP = 'sleep',
  REST = 'rest',
  HAMMER = 'hammer',
  SNEAKERS = 'sneakers',
  CLIPBOARD = 'clipboard',
  BULLET_LIST = 'bulletList',
  NOTIFICATIONS_BIT = 'notifications_bit',
  PORTFOLIOS = 'portfolios',
  Inspect = 'settings-inspect',
  HALF_STAR = 'halfStar',
  STAR_HAPPY = 'star-happy',
  KEY = 'iconKey',
  TIME_SCHEDULE2 = 'iconTimeSchedule',
  ALARM_CLOCK = 'iconAlarmClock',
  ESTIMATED_OCCUPANCY = 'iconEstimatedOccupancy',
  HOME_HEART2 = 'iconHomeHeart',
  CLIPBOARD2 = 'iconClipboard',
  BID = 'iconBid',
  MAKE_READY = 'iconMakeReady',
  RENO = 'iconReno', //Brush
  DESIGN_PIN = 'designPin',
  MOVE_IN = 'iconMoveIn',
  NEW_TRASH = 'new_trash',
  FINGER = 'finger',
  WHITE_PLUS = 'white_plus',
  GREEN_PLUS = 'greenPlus',
  VACANCY_LOSS = 'vacancyLoss',
  UNIT = 'unit',
  PROPERTY = 'property',
  KEY2 = 'key',
  JOIN_MODE = 'iconJoinMode',
  SPLIT_MODE = 'iconSplitMode',
  SENTIMENT_SATISFIED = 'good',
  SENTIMENT_NEUTRAL = 'fair',
  SENTIMENT_DISSATISFIED = 'poor',
  CLIPBOARD_LIST = 'Clipboard_list',
  CHAT = 'Chat',
  FLAG = 'Flag',
  SKIPPED = 'skipped',
  CHECK_NO = 'Check_no',
  CHECK_YES = 'Check_yes',
  SHARE = 'share',
  FILTER = 'iconFilter',
  ICON_CALENDAR = 'iconCalendar',
  CALENDAR = 'calendar',
  RATE = 'rate',
  ICON_HOME = 'iconHome',
  ICON_DOLLAR = 'iconDollar',
  ICON_JOIN = 'iconJoin',
  ICON_SPLIT = 'iconSplit',
  ICON_SEARCH = 'iconSearch',
  BB_TRASH = 'bb-trash',
  EMOJI = 'emojiDefault',
  EMOJI_HOVER = 'emojiHover',
  MOVE_OUT_POINT = 'moveOutPoint',
  MOVE_IN_POINT = 'moveInPoint',
  DELAY_ICON = 'delayIcon',
  APPROVAL_BOARD = 'approvalBoard',
  MOVE_IN_BOARD = 'moveInBoard',
  MOVE_OUT_BOARD = 'moveOutBoard',
  TURBO = 'turbo',
  RESEND_EMAIL = 'resendEmail',
  ICON_UPLOAD = 'iconUpload',
  ICON_FILE_UPLOADING = 'iconFileUploading',
  ICON_FILE_UPLOADED = 'iconFileUploaded',
  UPLOAD_ICON = 'upload-icon',
  DOWNLOAD = 'download',
  DOWNLOAD_ICON = 'download-icon',
  DELETE_FOREVER = 'delete-forever',
  PHOTO_CAMERA = 'photo-camera',
  THUMB_UP = 'thumb_up',
  THUMB_DOWN = 'thumb_down',
  YES = 'yes',
  NO = 'no',
  YES_ACTIVE = 'yes-active',
  NO_ACTIVE = 'no-active',
  MOUSE_DOUBLE_CLICK = 'mouse-double-click',
  CONTENT_COPY = 'content_copy',
  VERTICAL_DOTS = 'vertical_dots',
  MOOD = 'mood',
  THUMB_UP_DOWN = 'thumbs_up_down',
  INVITE = 'invite',
  REMOVE = 'remove_circle',
  ADD = 'add_circle',
  MAIL3 = 'mail3',
  ACCOUNT_CIRCLE = 'account_circle',
  LeaseUp = 'lease-up',
  Prelease = 'lease-up',
  ExternalLink = 'external-link',
  IconSnackbarSpinner = 'spinner',
  INFO = 'info',
  UNION = 'union',
  PLUS_FULL = 'plus_full',
  HELP = 'help',
  CLOSE_SMALL = 'close_small',
  INVENTORY = 'inventory',
  ZOOM_IN = 'zoom-in',
  FILE_PREVIEW = 'filePreview',
  PIECE_CIRCLE = 'piece_circle',
  FOLDER = 'folder',
  ARCHIVE_V2 = 'archive_v2',
  SQUARE_GRID = 'square_grid',
  THREE_COLUMNS = 'three_columns',
  TWO_ROWS = 'two_rows',
  PLUS_ROUNED = 'plus-rounded',
  MINUS_ROUNED = 'minus-rounded',
  HOLD = 'hold',
}
